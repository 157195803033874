import { defineStore } from 'pinia'
import { firestoreAction } from 'vuexfire'
import {
  mdiAccount,
  // mdiAnimation,
  // mdiBookInformationVariant,
  mdiCreation,
  mdiFormatListNumbered,
  mdiHistory,
  mdiPoll,
  // mdiPost,
  mdiTrendingUp,
  mdiWebClock,
  mdiCartCheck,
  mdiReceipt,
  mdiCreditCard,
  mdiKeyVariant,
  mdiWebhook,
  mdiApi,
} from '@mdi/js'

/* const timeout = function timeout(ms) {
  console.log('timing out ms:', ms)
  return new Promise((resolve) => setTimeout(resolve, ms))
} */

const navigation = [
  {
    showInAppBar: true,
    showInFooter: true,
    text: 'Products',
    showHeader: true,
    links: [
      {
        appIcon: true,
        text: 'Website Analyzer',
        description: 'Tech stack analysis tool',
        to: { name: 'products-website-analyzer' },
      },
    ],
  },
  {
    text: 'Pricing',
    showInAppBar: true,
    showHeader: true,
    to: { name: 'pricing' },
  },
  {
    showInAppBar: true,
    showInFooter: true,
    text: 'Resources',
    showHeader: true,
    links: [
      {
        text: 'Blog',
        description: 'Tech articles',
        to: { name: 'content-articles' },
      },
      {
        text: 'API Documentation',
        description: 'API integration guide',
        to: { name: 'products-website-analyzer-docs' },
      },
      {
        text: 'Technologies',
        description: 'Explore technologies & categories',
        to: { name: 'technologies' },
      },
      {
        text: 'Why AwesomeTechStack',
        description: 'How it works',
        to: { name: 'why' },
      },
      {
        text: 'FAQs',
        description: 'Common questions',
        to: { name: 'faq' },
      },
      {
        text: 'Remove Domain',
        description: 'Remove domain from AwesomeTechStack',
        to: { name: 'analysis-website-remove' },
        showInAppBar: false,
      },
    ],
  },
  /*{
    showInAppBar: true,
    showInFooter: true,
    text: 'Insights',
    showHeader: false,
    links: [
      {
        text: 'Technology insights',
        isSubheader: true,
      },
      {
        text: 'Top 10 awesome technologies',
        to: { name: 'insights-technologies-awesome' },
        icon: mdiTrendingUp,
      },
      {
        text: 'Top 10 most used technologies',
        to: { name: 'insights-technologies-most-used' },
        icon: mdiPoll,
      },
      {
        text: 'Website insights',
        isSubheader: true,
      },
      {
        text: 'Recently analyzed websites',
        icon: mdiWebClock,
        to: { name: 'insights-websites-recently-analyzed' },
      },
      {
        text: 'Top 10 tech stack websites',
        icon: mdiFormatListNumbered,
        to: {
          name: 'insights-websites-top-tech-stack',
        },
      },
      {
        text: 'Awesome websites',
        icon: mdiCreation,
        to: {
          name: 'insights-websites-awesome',
        },
      },
    ],
  },*/
  {
    showInAppBar: true,
    showInFooter: true,
    text: 'Insights',
    showHeader: true,
    links: [
      {
        text: 'Top 10 tech stack websites',
        icon: mdiFormatListNumbered,
        to: {
          name: 'insights-websites-top-tech-stack',
        },
      },
      {
        text: 'Awesome websites',
        icon: mdiCreation,
        to: {
          name: 'insights-websites-awesome',
        },
      },
      {
        text: 'Top 10 awesome technologies',
        to: { name: 'insights-technologies-awesome' },
        icon: mdiTrendingUp,
      },
      {
        text: 'Top 10 most used technologies',
        to: { name: 'insights-technologies-most-used' },
        icon: mdiPoll,
      },
    ],
  },
  {
    showInAppBar: false,
    showInFooter: true,
    text: 'Company',
    links: [
      {
        text: 'About us',
        to: { name: 'about' },
      },
      {
        text: 'Get in touch',
        to: { name: 'contact' },
      },
      {
        text: 'Terms of service',
        to: { name: 'terms' },
      },
      {
        text: 'Privacy policy',
        to: { name: 'privacy' },
      },
      {
        text: 'Imprint',
        to: { name: 'imprint' },
      },
    ],
  },
]

export const useStore = defineStore('main', {
  state: () => ({
    drawer: false,
    status: null,
    data: {},
    search: '',
    searchWithoutProtocol: '',
    searchProtocol: undefined,
    url: '',
    currentUrl: '',
    analyzingUrl: null,
    analyzingUrlSwitchedProtocolWithoutUrl: null,
    analyzingUrlWithoutProtocol: null,
    analyzingUrlProtocol: undefined,
    analyzingUrlSwitchedProtocol: '',
    analyzedStatusCode: '200',
    analyzedMsg: undefined,
    requestByIdResult: {},
    statusRequestById: undefined,
    urlValid: false,
    statusRequestSubmitContactForm: undefined,
    domain: '',
    domainWithoutWww: '',
    statusRequestRemoveWebsiteByUrl: undefined,
    removeWebsiteByUrl: {},
    statusRequestLighthouseReport: undefined,
    lighthouseReportHtml: '',
    runAnalyzer: false,
    loading: false,
    statusRequestFetchWebsiteStats: '',
    similarRankedItems: [],
    websiteScores: [],
    toc: [],
    initAnalyzeUrl: '',
    user: null,
    userLoading: true,
    fakeProgress: {
      interval: undefined,
      progress: 0,
    },
    statusLogin: undefined,
    statusLogout: undefined,
    showSignInSignUpDialog: '',
    userSignOutTriggered: false,
    authError: { message: '', code: undefined },
    statusSetupUser: undefined,
    lighthouseStatusCode: '200',
    lighthouseStatus: '',
    customer: {
      name: '',
      email: '',
      address: {
        line1: '',
        line2: '',
        postal_code: '',
        city: '',
        state: '',
        country: '',
      },
      taxIds: [],
    },
    authUser: null,
    breadcrumbs: null,
    requestId: null,
    intervalId: null,
  }),
  actions: {
    resetBreadcrumbs() {
      this.breadcrumbs = null
    },
    setBreadcrumbs(breadcrumbs) {
      this.breadcrumbs = breadcrumbs
    },
    login() {
      const nuxtApp = useNuxtApp()
      const { $auth } = nuxtApp

      this.setUserLoading(true)
      return new Promise((resolve, reject) => {
        setTimeout(async () => {
          try {
            if ($auth.currentUser) {
              const idToken = await $auth.currentUser.getIdToken()
              this.requestLogin()
              this.successRequestLogin(
                await $fetch(`/api/v2/login`, {
                  method: 'POST',
                  body: { idToken },
                }),
              )
              setTimeout(() => {
                resolve()
              })
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ message: 'no user' })
            }
          } catch (err) {
            console.error(err)
            this.failureRequestLogin()
            reject(err)
          } finally {
            this.setUserLoading(false)
          }
        })
      })
    },
    async logout() {
      this.requestLogout()
      try {
        this.successRequestLogout(
          await $fetch('/api/v2/logout', {
            method: 'POST',
          }),
        )
      } catch (err) {
        console.error(err)
        this.failureRequestLogout()
      }
    },
    async getUser() {
      this.setUserLoading(true)
      try {
        const data = await $fetch('/api/v2/user', {
          method: 'GET',
        })
        if (!data && !Object.keys(data).length) {
          this.setUser(null)
          this.setAuthenticatedUser(null)
        } else {
          this.setUser(data)
          this.setAuthenticatedUser(data)
        }
      } catch (err) {
        // ignore
      } finally {
        this.setUserLoading(false)
      }
    },
    async setupUser({ name, email, jobTitleId, technologies }) {
      technologies = this.user?.technologies
      jobTitleId = this.user?.jobTitleId
      this.setUserLoading(true)
      this.requestSetupUser()
      try {
        const nuxtApp = useNuxtApp()
        const { $auth } = nuxtApp
        const authUser = $auth.currentUser
        await $fetch('/api/v2/user', {
          method: 'POST',
          body: {
            name: name || this.user?.name || authUser.displayName,
            email: email || authUser.email,
            jobTitleId,
            technologies,
          },
        })
        await this.getUser()
        this.successRequestSetupUser()
      } catch (err) {
        this.failureRequestSetupUser()
        // ignore
      } finally {
        this.setUserLoading(false)
      }
    },
    async deleteUser() {
      this.setUserLoading(true)
      try {
        const nuxtApp = useNuxtApp()
        const { $auth } = nuxtApp
        const authUser = $auth.currentUser
        try {
          await $fetch('/api/v2/user', { method: 'DELETE' })
          const { gtag } = useGtag()
          gtag('event', 'userDelete')
          window.location.assign('/user/deleted')
        } catch (e) {
          if (e.code === 'auth/requires-recent-login') {
            if (authUser.providerData[0].providerId === 'google.com') {
              this.googleSignIn()
              this.deleteUser()
            } else {
              this.gitHubSignIn()
              this.deleteUser()
            }
          }
        }
      } catch (err) {
        // ignore
      } finally {
        this.setUserLoading(false)
      }
    },
    googleSignIn: firestoreAction(async function () {
      const provider = new this.$fireModule.auth.GoogleAuthProvider()
      this.$fire.auth.useDeviceLanguage()
      await this.$fire.auth.signInWithPopup(provider)
    }),
    gitHubSignIn: firestoreAction(async function () {
      const provider = new this.$fireModule.auth.GithubAuthProvider()
      this.$fire.auth.useDeviceLanguage()
      await this.$fire.auth.signInWithPopup(provider)
    }),
    async signOut() {
      this.setUser(null)
      this.setAuthenticatedUser(null)

      const nuxtApp = useNuxtApp()
      const { $auth } = nuxtApp
      await $auth.signOut()
      this.setUserSignOutTriggered(true)
    },
    async onIdTokenChangedAction() {
      if (process.client) {
        if (this.statusLogin === 'success') {
          await this.login()
        }
      }
    },
    async onAuthStateChangedAction() {
      const nuxtApp = useNuxtApp()
      const { $auth } = nuxtApp
      if (process.client) {
        if (!$auth.currentUser) {
          this.setUser(null)
          this.setAuthenticatedUser(null)

          this.setUserLoading(false)
          try {
            await this.logout()
          } catch (err) {
            console.error(err)
            this.setUserLoading(false)
          }
        } else {
          try {
            await this.login()
            await this.getUser()
          } catch (err) {
            console.error(err)
            await this.logout()
          }
        }
      }
    },
    async setSearch({ url = this.search, parseSearch = false }) {
      try {
        if (typeof url !== 'string') {
          return
        }
        url = decodeURIComponent(url)
        this.mutateSearch(url.toLowerCase())
        if (url.length > 0) {
          let parsedUrl = ''

          try {
            let tempUrl = url
            let isHttps = url.indexOf('https://') === 0
            const hasProtocol = isHttps || url.indexOf('http:') === 0
            if (!hasProtocol) {
              tempUrl = 'https://' + tempUrl
              isHttps = true
            }
            const newUrl = new URL(tempUrl)
            this.setUrlValid(true)
            this.setDomain(newUrl.hostname)

            if (newUrl.hostname.indexOf('www.') === 0) {
              newUrl.hostname = newUrl.hostname.substring(4)
            }

            this.setDomainWithoutWww(newUrl.hostname)

            if (isHttps) {
              parsedUrl = `https://${newUrl.hostname}`
            } else {
              parsedUrl = `http://${newUrl.hostname}`
            }

            if (parseSearch) {
              this.mutateSearch(parsedUrl)
            }
            this.setUrl(parsedUrl)
          } catch (err) {
            console.error(err.message)
            this.setUrlValid(false)
          }
        } else {
          this.setUrlValid(false)
        }
      } catch (err) {
        console.error(err)
        this.mutateSearch('')
        this.setUrlValid(false)
      }
    },
    async requestById({ force = false, status = 200, onlyFetch = false }) {
      try {
        this.currentUrl = ''
        const route = useRoute()
        const { protocol } = route.query
        const { slug = '' } = route.params
        const url = this.searchWithoutProtocol || slug

        this.setSearch({ parseSearch: true })
        if (this.urlValid) {
          this.status = undefined
          this.statusRequestById = 'fetching'

          const data = await $fetch(
            `/api/v1/websites/${encodeURI(this.domain)}`,
            {
              method: 'GET',
              params: {
                t: force ? new Date().getTime() : undefined,
              },
            },
          )

          if (!data || Object.keys(data).length < 1) {
            this.successRequestById({})
            this.successRequestFetchWebsiteStats({})
            if (!onlyFetch) {
              this.runAnalyzer = { msg: 'analyze-new-website' }
            }
          } else {
            const { _id, score } = data

            const responseProtocol = data.protocol || 'https:'
            if (protocol) {
              if (protocol !== responseProtocol) {
                if (!onlyFetch) {
                  this.runAnalyzer = {
                    msg: 'update-analysis-different-protocol',
                  }
                }
              }
            } else {
              this.setSearch({
                url: `${protocol || responseProtocol}//${url}`,
                parseSearch: true,
              })
            }

            await this.fetchWebsiteStats({ _id, score })

            data.techs = data.techs.filter((item) => item.confidence > 0)
            this.successRequestById(data)
          }
        }
      } catch (err) {
        console.error(err)
        this.failureRequestById()
      }
    },
    async initAnalyze({ url, onlyFetch = false }) {
      this.setInitAnalyzeUrl({ url })
      this.setSearch({ url })
      await this.requestById({ onlyFetch })
    },
    async fetchWebsiteStats({
      _id = this.requestByIdResult._id,
      score = this.requestByIdResult.score,
      force = false,
    }) {
      this.requestFetchWebsiteStats()
      let websiteScores = []
      let similarRankedItems = []

      if (_id) {
        try {
          const similarRankedRequest = await $fetch(
            `/api/v1/websites/similar-ranked/index?limit=5${
              score ? `&score=${score}` : ''
            }&excludeId=${_id}`,
          )

          const websiteScoresRequest = await $fetch(
            `/api/v1/websites/${_id}/scores`,
            {
              method: 'GET',
              params: {
                t: force ? new Date().getTime() : undefined,
              },
            },
          )

          similarRankedItems = similarRankedRequest

          websiteScores = websiteScoresRequest
            .map((item) => ({
              total: item.score,
              date: item.updatedTimeAgoText,
            }))
            .reverse()

          /*if (websiteScoresRequest.length === 1) {
            websiteScores.push({
              total: websiteScoresRequest[0].score,
              date: websiteScoresRequest[0].updatedTimeAgoText,
            })
          }*/
        } catch (err) {
          console.error(err)
          this.failureRequestFetchWebsiteStats()
        }
      }

      this.successRequestFetchWebsiteStats({
        similarRankedItems,
        websiteScores,
      })
    },
    async analyze() {
      try {
        if (this.status !== 'fetching') {
          // const { CancelToken } = this.$axios
          await this.setSearch({ parseSearch: true })
          if (this.urlValid) {
            this.fetchingAnalyze()
            try {
              this.setAnalyzingUrl(this.url)
              // let error = false
              let requestStatusCode = 200
              let errorMsg = undefined
              let requestId = null

              // this.$axios.defaults.withCredentials = true
              const runtimeConfig = useRuntimeConfig()
              const { data, error } = await useFetch(
                `${runtimeConfig.public.ANALYZE_API_ENDPOINT}/api/v2/websites/analyze`,
                {
                  method: 'POST',
                  body: {
                    url: this.url,
                  },
                  credentials: 'include',
                  onResponse({ response }) {
                    const { status = 200, _data = {} } = response
                    requestStatusCode = status
                    requestId = _data.id
                  },
                  onResponseError({ response }) {
                    const { status = 200, _data = {} } = response
                    const { error = undefined } = _data
                    requestStatusCode = status
                    errorMsg = error
                  },
                },
              )

              let statusCode = data.value?.statusCode ?? requestStatusCode

              if (statusCode == 200 && requestId) {
                this.requestId = requestId
                this.startFetchingRequestStatus()
              } else {
                if (error.value) {
                  this.failureFetchingAnalyze({
                    status: statusCode.toString(),
                    msg: errorMsg,
                  })
                  return
                }
              }

              /*if (statusCode === 202) {
                this.acceptedFetchingAnalyze()
                return
              }

              if (canceled) {
                this.analyzeCanceled = true
                this.analyzeCanceledUrl = this.url
                this.successFetchingAnalyze({})
                return
              } else if (error.value) {
                this.failureFetchingAnalyze({
                  status: statusCode.toString(),
                  msg: errorMsg,
                })
                return
              }

              statusCode = statusCode.toString()

              if (parseInt(statusCode) < 400) {
                this.successFetchingAnalyze(analyzedData.value)
                await this.requestById({ force: true })
              } else {
                this.failureFetchingAnalyze({ status: statusCode })
              }*/
            } catch (err) {
              console.error(err)
              this.failureFetchingAnalyze(err)
              this.stopFetchingRequestStatus()
            }
          }
        }
      } catch (err) {
        console.error(err)
        return err
      }
    },
    startFetchingRequestStatus() {
      this.stopFetchingRequestStatus()
      if (this.requestId && this.status === 'fetching') {
        this.intervalId = setInterval(() => {
          this.fetchRequestStatus({ id: this.requestId })
        }, 1000)
      }
    },
    stopFetchingRequestStatus() {
      clearInterval(this.intervalId)
    },
    async fetchRequestStatus({ id }) {
      const runtimeConfig = useRuntimeConfig()
      const running = false
      const data = await $fetch(
        `${runtimeConfig.public.ANALYZE_API_ENDPOINT}/api/v2/websites/analyze/status/${id}`,
        {
          method: 'GET',
          credentials: 'include',
        },
      )

      if (!data && !Object.keys(data).length) {
        this.stopFetchingRequestStatus()
      } else {
        const { running, statusCode, statusResult } = data
        if (!running) {
          this.stopFetchingRequestStatus()
          if (statusCode == 200) {
            this.successFetchingAnalyze({ statusCode, statusResult })
            await this.requestById({ force: true })
          } else {
            this.failureFetchingAnalyze({ statusCode, statusResult })
          }
        }
      }
    },
    async removeWebsiteByUrl({ url }) {
      try {
        this.requestRemoveWebsiteByUrl()
        const response = await $fetch(`/api/v2/websites/remove`, {
          method: 'POST',
          body: JSON.stringify({ url: encodeURI(url) }),
        })
        this.successRequestRemoveWebsiteByUrl(response)
      } catch (e) {
        this.failureRequestRemoveWebsiteByUrl()
      }
    },
    async getLighthouseReport({ url }) {
      let requestStatusCode = 200
      try {
        this.requestLighthouseReport()

        const runtimeConfig = useRuntimeConfig()
        const response = await $fetch(
          `${
            runtimeConfig.public.ANALYZE_API_ENDPOINT
          }/api/v2/websites/report?url=${encodeURI(url)}`,
          {
            method: 'POST',
            credentials: 'include',
            onResponse({ request, response, options }) {
              const { status = 200 } = response
              requestStatusCode = status
            },
          },
        )

        this.successRequestLighthouseReport(response)
      } catch (error) {
        this.failureRequestLighthouseReport({
          code: requestStatusCode,
          status: error?.data?.error ?? requestStatusCode ?? 500,
        })
      }
    },
    async submitContact({ data = {}, token = '' }) {
      this.requestSubmitContactForm = true

      try {
        const response = await $fetch(`/api/v2/contact/submit`, {
          method: 'POST',
          headers: {
            'X-Firebase-AppCheck': token,
          },
          body: JSON.stringify(data),
        })
        this.successSubmitContactForm = response
        window.location.href = '/contact/success'
        return true
      } catch (err) {
        console.error(err)
        this.failureSubmitContactForm = true
      }
      return false
    },
    showSignInDialog() {
      const { gtag } = useGtag()
      gtag('event', 'signIn', { name: 'show' })
      this.showSignInSignUpDialog = ''
      this.showSignInSignUpDialog = 'signin'
    },

    showSignUpDialog() {
      const { gtag } = useGtag()
      gtag('event', 'signUp', { name: 'show' })
      this.showSignInSignUpDialog = ''
      this.showSignInSignUpDialog = 'signup'
    },

    setDrawer(value) {
      this.drawer = value
    },

    fetchingAnalyze() {
      this.requestId = null
      this.status = 'fetching'
    },

    successFetchingAnalyze({ statusResult, statusCode }) {
      this.status = 'success'
      this.analyzedStatusCode = statusCode
      this.analyzedMsg = statusResult
      this.data = {}
      this.runAnalyzer = false
    },

    acceptedFetchingAnalyze() {
      this.status = 'accepted'
      this.runAnalyzer = false
    },

    failureFetchingAnalyze({ statusCode = 'invalid URL', statusResult }) {
      this.analyzedStatusCode = statusCode
      this.analyzedMsg = statusResult
      if (this.analyzedStatusCode === 401) {
        this.showSignInSignUpDialog = 'signup'
      }
      this.status = 'failure'
      this.runAnalyzer = false
    },

    mutateSearch(value = '') {
      this.search = value
      this.searchWithoutProtocol =
        this.search.indexOf('https://') === 0
          ? value.replace('https://', '')
          : value.replace('http://', '')
      this.searchProtocol =
        value.indexOf('https://') === 0
          ? 'https:'
          : value.indexOf('http://') === 0
            ? 'http:'
            : undefined
    },

    setUrl(value) {
      this.url = value
    },

    setDomain(value) {
      this.domain = value
    },

    setDomainWithoutWww(value) {
      this.domainWithoutWww = value
    },

    setAnalyzingUrl(value = '') {
      this.analyzingUrl = value
      const analyzingUrlIsHttps = value.indexOf('https:') === 0
      this.analyzingUrlProtocol = analyzingUrlIsHttps ? 'https:' : 'http:'
      this.analyzingUrlWithoutProtocol = analyzingUrlIsHttps
        ? value.replace('https://', '')
        : value.replace('http://', '')
      this.analyzingUrlSwitchedProtocol = analyzingUrlIsHttps
        ? value.replace('https:', 'http:')
        : value.replace('http:', 'https:')
      this.analyzingUrlSwitchedProtocolWithoutUrl = analyzingUrlIsHttps
        ? 'http:'
        : 'https:'
    },

    resetCurrentUrl() {
      this.currentUrl = ''
      this.initAnalyzeUrl = ''
    },

    successRequestById(value) {
      this.statusRequestById = 'success'
      this.requestByIdResult = value
      this.currentUrl = this.url
    },

    failureRequestById() {
      this.currentUrl = ''
      this.statusRequestById = 'failure'
    },

    setUrlValid(value) {
      this.urlValid = value
    },

    requestSubmitContactForm() {
      this.statusRequestSubmitContactForm = 'fetching'
    },

    successSubmitContactForm() {
      this.statusRequestSubmitContactForm = 'success'
    },

    failureSubmitContactForm() {
      this.statusRequestSubmitContactForm = 'failure'
    },

    setFetchingStatus(value) {
      this.fetchingStatus = value
    },

    setRunAnalyzer(value) {
      this.runAnalyzer = value
    },

    setShowSignInSignUpDialog(value) {
      this.showSignInSignUpDialog = value
    },

    setInitAnalyzeUrl(value) {
      this.initAnalyzeUrl = value
    },

    setAuthError({ message, code }) {
      this.authError = { message, code }
    },

    requestRemoveWebsiteByUrl() {
      this.statusRequestRemoveWebsiteByUrl = 'fetching'
    },

    successRequestRemoveWebsiteByUrl({ data = {} }) {
      this.statusRequestRemoveWebsiteByUrl = 'success'
      this.removeWebsiteByUrl = data
    },

    failureRequestRemoveWebsiteByUrl() {
      this.statusRequestRemoveWebsiteByUrl = 'failure'
    },

    requestLighthouseReport() {
      this.lighthouseStatusCode = 200
      this.lighthouseStatus = ''
      this.lighthouseReportHtml = ''
      this.statusRequestLighthouseReport = 'fetching'
    },

    successRequestLighthouseReport(rawHtml) {
      this.statusRequestLighthouseReport = 'success'
      this.lighthouseReportHtml = rawHtml
    },

    failureRequestLighthouseReport({ code, status = 'invalid URL' }) {
      this.lighthouseStatusCode = code
      this.lighthouseStatus = status

      if (this.lighthouseStatusCode === 401) {
        this.showSignInSignUpDialog = 'signup'
      }
      this.statusRequestLighthouseReport = 'failure'
    },

    setToc(value) {
      this.toc = value
    },

    resetToc() {
      this.toc = []
    },

    requestFetchWebsiteStats() {
      this.statusRequestFetchWebsiteStats = 'fetching'
    },

    successRequestFetchWebsiteStats({
      similarRankedItems = [],
      websiteScores = [],
    }) {
      this.similarRankedItems = similarRankedItems
      this.websiteScores = websiteScores
      this.statusRequestFetchWebsiteStats = 'success'
    },

    failureRequestFetchWebsiteStats() {
      this.statusRequestFetchWebsiteStats = 'failure'
    },

    setUser(value = {}) {
      this.user = value
    },

    setAuthenticatedUser(value = {}) {
      this.authUser = value
    },

    setUserLoading(value) {
      this.userLoading = value
      if (this.userLoading) {
        this.authError = { code: undefined, message: '' }
      }
    },

    requestLogout() {
      this.statusLogout = 'fetching'
    },

    successRequestLogout() {
      this.statusLogout = 'success'
    },

    failureRequestLogout() {
      this.statusLogout = 'failure'
    },

    requestLogin() {
      this.statusLogin = 'fetching'
    },
    successRequestLogin() {
      setTimeout(() => {
        this.statusLogin = 'success'

        if (
          (this.analyzedStatusCode === 401 ||
            this.analyzedStatusCode === '401') &&
          this.initAnalyzeUrl
        ) {
          this.runAnalyzer = true
        }

        this.showSignInSignUpDialog = ''
      })
    },

    failureRequestLogin() {
      this.statusLogin = 'failure'
    },

    hideSignInSignUpDialog() {
      this.showSignInSignUpDialog = ''
      this.authError = { code: undefined, message: '' }
    },

    setUserSignOutTriggered(value) {
      this.userSignOutTriggered = value
    },

    resetAuthError() {
      this.authError = { code: undefined, message: '' }
    },

    requestSetupUser() {
      this.statusSetupUser = 'fetching'
    },

    successRequestSetupUser() {
      const { gtag } = useGtag()
      gtag('event', 'userUpdate', { status: 'success' })
      this.statusSetupUser = 'success'
    },

    failureRequestSetupUser() {
      const { gtag } = useGtag()
      gtag('event', 'userUpdate', { status: 'error' })
      this.statusSetupUser = 'failure'
    },

    resetStatusSetupUser() {
      this.statusSetupUser = undefined
    },

    getStatus({ score = -1 }) {
      if (score === -1) {
        return undefined
      }

      if (score <= 49) {
        return 'error'
      } else if (score <= 89) {
        return 'warning'
      } else {
        return 'success'
      }
    },
  },
  getters: {
    isAuthenticated(state) {
      const { email, uid } = state.authUser || {}
      if (email && uid) {
        return true
      } else {
        return false
      }
    },

    userLinks(state) {
      const links = [
        {
          to: { name: 'user-account' },
          text: 'Account',
          icon: mdiAccount,
        },
        {
          to: { name: 'user-subscription' },
          text: 'Subscriptions',
          icon: mdiCartCheck,
        },
        {
          to: { name: 'user-payment-methods' },
          text: 'Payment methods',
          icon: mdiCreditCard,
        },
        {
          to: { name: 'user-invoice' },
          text: 'invoices',
          icon: mdiReceipt,
        },
        {
          to: { name: 'user-history' },
          text: 'History',
          icon: mdiHistory,
        },
        {
          to: { name: 'products-website-analyzer-docs' },
          text: 'API documentation',
          icon: mdiApi,
        },
        {
          to: { name: 'user-api-keys' },
          text: 'API keys',
          icon: mdiKeyVariant,
        },
        {
          to: { name: 'user-webhook-endpoints' },
          text: 'Webhook endpoints',
          icon: mdiWebhook,
        },
        // { to: { name: 'user-stories' }, text: 'Stories', icon: mdiPost },
      ]

      return links.map((link) => {
        const show =
          this.isAuthenticated &&
          state.user &&
          (link.to.name !== 'user-payment-methods' || state.user.isCustomer)
        return { ...link, show }
      })
    },
    appBarNavigation: () => {
      return navigation
        .filter((item) => item.showInAppBar)
        .map((item) => ({
          ...item,
          links: item.links
            ? item.links.filter((link) => link.showInAppBar !== false)
            : [],
        }))
    },
    footerNavigation: () => {
      return navigation
        .filter((item) => item.showInFooter)
        .map((item) => ({
          ...item,
          links: item.links
            ? item.links.filter((link) => link.showInFooter !== false)
            : [],
        }))
    },
    navigation: () => {
      return navigation
    },
  },
})
