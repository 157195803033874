import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_99tyrJVxV3tJlUbnb2rNb2lrWZJ3yCVaG393DnED9Fg from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_rUDZiDyu2mz5GP9COOWWhZy5JxTjYdMqtxbGqnYBOkg from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.js";
import switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_client_6YCPEsU79e9ERhHq5su2zFcu48maKnh46pu3Nz_pG5c from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import i18n_84QbCrEJidQfHX79evFy6rRgOFjwLXHugOxbWoIVeIQ from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/node_modules/nuxt-site-config/dist/runtime/app/plugins/i18n.js";
import analytics_client_D__0bhX73uC0vhcsTxv5CvOp30_GkDRY3Sfq7hDlz_U from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/plugins/analytics.client.ts";
import filter_nh8ZGeGImouSSLiEhMWVKyA1Ab4wuOKCdBVAJFOcDyo from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/plugins/filter.js";
import firebase_client_CoJHhrT3ZeDUrul2mpvKodStAmSgY7mH391tLYlN4AA from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/plugins/firebase.client.ts";
import scroll_client_uZFVB3C1Nwa6z0L99D6M7JwqmGq9RpWGeN6TjRQtVkc from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/plugins/scroll.client.ts";
import vuetify_hjFy4UiBVKu2U8_BW9ggkFzfvErKr3wFgTHpa6TF5Ds from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/plugins/vuetify.ts";
import ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_client_99tyrJVxV3tJlUbnb2rNb2lrWZJ3yCVaG393DnED9Fg,
  plugin_rUDZiDyu2mz5GP9COOWWhZy5JxTjYdMqtxbGqnYBOkg,
  switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw,
  route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U,
  i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM,
  plugin_client_6YCPEsU79e9ERhHq5su2zFcu48maKnh46pu3Nz_pG5c,
  i18n_84QbCrEJidQfHX79evFy6rRgOFjwLXHugOxbWoIVeIQ,
  analytics_client_D__0bhX73uC0vhcsTxv5CvOp30_GkDRY3Sfq7hDlz_U,
  filter_nh8ZGeGImouSSLiEhMWVKyA1Ab4wuOKCdBVAJFOcDyo,
  firebase_client_CoJHhrT3ZeDUrul2mpvKodStAmSgY7mH391tLYlN4AA,
  scroll_client_uZFVB3C1Nwa6z0L99D6M7JwqmGq9RpWGeN6TjRQtVkc,
  vuetify_hjFy4UiBVKu2U8_BW9ggkFzfvErKr3wFgTHpa6TF5Ds,
  ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs
]