
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as faqtkvDhA9qXFl8t2Y2tiASQ09c2lztJ9ZIkYp_451JBovekMeta } from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/faq.vue?macro=true";
import { default as whyygiJ_45dZWXtDjh0eNNISv_45JO9mG_1P1s40iYPwQpIZtgMeta } from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/why.vue?macro=true";
import { default as aboutxMAoNFFDvHpHq8hqzAr_1jQU0IZc1RXhSMNxTjXyuHsMeta } from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/about.vue?macro=true";
import { default as indexMwYGd_hIxRFEWRa4PdhLWRSztpHXmLIXaLPsx3DQ2zgMeta } from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/index.vue?macro=true";
import { default as termsFXIsdznDM_FxjNVhfjfx041prxP3F4tgjgJgN5nmjKoMeta } from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/terms.vue?macro=true";
import { default as contact8f38SZx2NdBrqnB1GsnE2vYzUesaCYjVbAd5DGtlybcMeta } from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/contact.vue?macro=true";
import { default as imprintGqkiSffCzqJdeH3O4fwhEn9BL42WTMH1BVJ7hlehbOAMeta } from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/imprint.vue?macro=true";
import { default as pricinghQiS2HZjnnhVDeZMSp5iJDZtOX1L5dxBehUDGPbSebkMeta } from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/pricing.vue?macro=true";
import { default as privacyDZ86JCNhy5UGFBm2OhPTGGdO03cuiu515QPhnYZt6tsMeta } from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/privacy.vue?macro=true";
import { default as accountfgO9YPE0rdxTwilpRsyL1I7ZZ8Ju4TKAwQ993PsKx90Meta } from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/user/account.vue?macro=true";
import { default as deletedrSamtSbIkeAnfQRlIBFH_45RWdXmdDcqFRdAPzD6Kquj0Meta } from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/user/deleted.vue?macro=true";
import { default as historynxJdq_3V3rFT7noZMqk4peE8YO_m0yO5pQnH5ohxqqMMeta } from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/user/history.vue?macro=true";
import { default as invoicexIxH09Fp6xrFrAZeSIYb6B8l__naFTAvVE1_X0_gkRYMeta } from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/user/invoice.vue?macro=true";
import { default as api_45keys8Ec9wtcpbBVJq0c2_MXcMqqX4azvlltjGCS3_45ezsyesMeta } from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/user/api-keys.vue?macro=true";
import { default as subscriptionx54VdwkLnqlwrWdltgtAQQxypVZp7n_1TcC4JILdqioMeta } from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/user/subscription.vue?macro=true";
import { default as indexTELu5QA8YTY6VQZmuiVgOwlzoPnZKSs6U03JgJTKQ7gMeta } from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/technologies/index.vue?macro=true";
import { default as _91slug_93Pum3Bz_45bC_H28gvmroip7SuR6yiX8S1jt3qUi5__45jV8Meta } from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/technologies/[slug].vue?macro=true";
import { default as payment_45methodslMVvrKdtsKeiY6w6Ycdq3lRe78d3zqwP09jM_45NE1qWQMeta } from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/user/payment-methods.vue?macro=true";
import { default as indexJHtmcB6ckvWqjPPUna_D4zXwE1wtbji6g_45UCzErKvSIMeta } from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/content/articles/index.vue?macro=true";
import { default as webhook_45endpointsFAH4YLwFo4kGR_45hvUa_fFT_45bYh7deTtvPUGMi0iDNOwMeta } from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/user/webhook-endpoints.vue?macro=true";
import { default as _91slug_93rnWwvs25XuEHiiXze5Cnj2rGnJXVa5AqGnwJnGTrrpUMeta } from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/analysis/website/[slug].vue?macro=true";
import { default as removew2UDUMl5FkPCVuEYqsGS10xImfTVRovxJiR1zh6_f68Meta } from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/analysis/website/remove.vue?macro=true";
import { default as _91slug_93cK_kRUTat18IHMjLE8OpoKP5ajPEABzSOKNawtD4rAgMeta } from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/content/articles/[slug].vue?macro=true";
import { default as awesomeBoXh_45SxaRirezf_453fRXmaeZ8sZo3Y6wzGDQuiTzKOtEMeta } from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/insights/websites/awesome.vue?macro=true";
import { default as awesomebUcPXiByebpdrM4OvfXnAA7hdOicrbUqd6ZQ5hIveOEMeta } from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/insights/technologies/awesome.vue?macro=true";
import { default as docstBvL28Hr2leoHrWVEHB7Fkcv_45U_454uyTFISujcULM0SYMeta } from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/products/website-analyzer/docs.vue?macro=true";
import { default as _91slug_93Hib7JBIbbv87K_UjR5BJwhkhy6d_o7yNMycS4aI7zAIMeta } from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/technologies/[category]/[slug].vue?macro=true";
import { default as most_45used5s9hGjdcnKtUyAj_45EZGY2oi7aLqtP1w_Cbwb8MBvIxIMeta } from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/insights/technologies/most-used.vue?macro=true";
import { default as indexPonk9kgaxPzbz0WgoekUkmRQbM2Nj_WuLhK6RlPXOXMMeta } from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/products/website-analyzer/index.vue?macro=true";
import { default as top_45tech_45stackQU90SZ9uW8O8ZTH4OxzpEmlsP1fvBBvLWLUdUnHR_45hUMeta } from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/insights/websites/top-tech-stack.vue?macro=true";
import { default as lighthousepsnfYxapx_DGF2S6xE4tcNd92L7OBl4VW6tz9tDOXPEMeta } from "D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/analysis/website/reports/lighthouse.vue?macro=true";
export default [
  {
    name: "faq",
    path: "/faq",
    component: () => import("D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/faq.vue")
  },
  {
    name: "why",
    path: "/why",
    component: () => import("D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/why.vue")
  },
  {
    name: "about",
    path: "/about",
    component: () => import("D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/about.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/index.vue")
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/terms.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/contact.vue")
  },
  {
    name: "imprint",
    path: "/imprint",
    component: () => import("D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/imprint.vue")
  },
  {
    name: "pricing",
    path: "/pricing",
    component: () => import("D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/pricing.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/privacy.vue")
  },
  {
    name: "user-account",
    path: "/user/account",
    component: () => import("D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/user/account.vue")
  },
  {
    name: "user-deleted",
    path: "/user/deleted",
    component: () => import("D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/user/deleted.vue")
  },
  {
    name: "user-history",
    path: "/user/history",
    component: () => import("D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/user/history.vue")
  },
  {
    name: "user-invoice",
    path: "/user/invoice",
    component: () => import("D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/user/invoice.vue")
  },
  {
    name: "user-api-keys",
    path: "/user/api-keys",
    component: () => import("D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/user/api-keys.vue")
  },
  {
    name: "user-subscription",
    path: "/user/subscription",
    component: () => import("D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/user/subscription.vue")
  },
  {
    name: "technologies",
    path: "/technologies",
    component: () => import("D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/technologies/index.vue")
  },
  {
    name: "technologies-slug",
    path: "/technologies/:slug()",
    component: () => import("D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/technologies/[slug].vue")
  },
  {
    name: "user-payment-methods",
    path: "/user/payment-methods",
    component: () => import("D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/user/payment-methods.vue")
  },
  {
    name: "content-articles",
    path: "/content/articles",
    component: () => import("D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/content/articles/index.vue")
  },
  {
    name: "user-webhook-endpoints",
    path: "/user/webhook-endpoints",
    component: () => import("D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/user/webhook-endpoints.vue")
  },
  {
    name: "analysis-website-slug",
    path: "/analysis/website/:slug()",
    component: () => import("D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/analysis/website/[slug].vue")
  },
  {
    name: "analysis-website-remove",
    path: "/analysis/website/remove",
    component: () => import("D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/analysis/website/remove.vue")
  },
  {
    name: "content-articles-slug",
    path: "/content/articles/:slug()",
    component: () => import("D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/content/articles/[slug].vue")
  },
  {
    name: "insights-websites-awesome",
    path: "/insights/websites/awesome",
    component: () => import("D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/insights/websites/awesome.vue")
  },
  {
    name: "insights-technologies-awesome",
    path: "/insights/technologies/awesome",
    component: () => import("D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/insights/technologies/awesome.vue")
  },
  {
    name: "products-website-analyzer-docs",
    path: "/products/website-analyzer/docs",
    component: () => import("D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/products/website-analyzer/docs.vue")
  },
  {
    name: "technologies-category-slug",
    path: "/technologies/:category()/:slug()",
    component: () => import("D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/technologies/[category]/[slug].vue")
  },
  {
    name: "insights-technologies-most-used",
    path: "/insights/technologies/most-used",
    component: () => import("D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/insights/technologies/most-used.vue")
  },
  {
    name: "products-website-analyzer",
    path: "/products/website-analyzer",
    component: () => import("D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/products/website-analyzer/index.vue")
  },
  {
    name: "insights-websites-top-tech-stack",
    path: "/insights/websites/top-tech-stack",
    component: () => import("D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/insights/websites/top-tech-stack.vue")
  },
  {
    name: "analysis-website-reports-lighthouse",
    path: "/analysis/website/reports/lighthouse",
    component: () => import("D:/akubasa/workspace_save/awesometechstack/run/nuxt-service/pages/analysis/website/reports/lighthouse.vue")
  }
]