import { useStore } from '@/composables/store'

export default defineNuxtRouteMiddleware((to, from) => {
  // If middleware is called from hot module replacement, ignore it
  if (process.server) return
  const nuxtApp = useNuxtApp()
  if (process.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered)
    return

  const store = useStore()
  store.hideSignInSignUpDialog()
  store.resetStatusSetupUser()

  // when analysis route is empty, just redirect to home page
  if (to.name === 'analysis-website-slug' && !to.params.slug) {
    store.setUrlValid(false)
    return navigateTo('/')
  }

  // reset search when leaving website analysis detail page
  if (to.name !== 'analysis-website-slug') {
    store.resetCurrentUrl()
    store.setSearch({ url: '' })
    store.stopFetchingRequestStatus()
  }
})
