import { useStore } from '@/composables/store'

export default defineNuxtRouteMiddleware((to, from) => {
  const excludedRoutes = [
    'insights-websites-top-tech-stack',
    'insights-technologies-awesome',
    'insights-technologies-most-used',
  ]

  if (
    typeof to.name === 'string' &&
    !excludedRoutes.includes(to.name) &&
    to.query.num_of_days
  ) {
    return navigateTo({ ...to, query: { ...to.query, num_of_days: undefined } })
  }
})
