import { createVuetify, type ThemeDefinition } from 'vuetify'
import { md3 } from 'vuetify/blueprints'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
import { VDataTable } from 'vuetify/components/VDataTable'
import 'vuetify/styles'

const dark: ThemeDefinition = {
  dark: true,
  colors: {
    background: '#020617',
    'on-background': '#ffffff',
    primary: '#f472b6',
    surface: '#0F172A',
    'surface-variant': '#1E293B',
    'on-surface-variant': '#ffffff',
    'on-primary': '#000000',

    'awesome-1': '#fc619d',
    'on-awesome-1': '#000000',
    'awesome-2': '#ff9adf',
    'on-awesome-2': '#000000',
    'awesome-3': '#ffd4ff',
    'on-awesome-3': '#000000',
  },
}

const light: ThemeDefinition = {
  dark: false,
  colors: {
    background: '#ffffff',
    'on-background': '#0f172a',
    primary: '#994870',
    surface: '#ffffff',
    'surface-variant': '#F1F5F9',
    'on-surface-variant': '#334155',
    'on-primary': '#ffffff',
    'awesome-1': '#fc619d',
    'on-awesome-1': '#ffffff',
    'awesome-2': '#ff9adf',
    'on-awesome-2': '#0f172a',
    'awesome-3': '#ffd4ff',
    'on-awesome-3': '#0f172a',
  },
}

export default defineNuxtPlugin((nuxtApp) => {
  const vuetify = createVuetify({
    components: {
      VDataTable,
    },
    defaults: {
      VBtn: {
        rounded: 'none',
      },
      VAlert: {
        variant: 'tonal',
      },
      VProgressLinear: {
        rounded: 'lg',
      },
    },
    ssr: true,
    blueprint: md3,
    icons: {
      defaultSet: 'mdi',
      aliases,
      sets: {
        mdi,
      },
    },
    theme: {
      defaultTheme: 'light',
      themes: {
        dark,
        light,
      },
    },
  })

  nuxtApp.vueApp.use(vuetify)
})
